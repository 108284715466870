<template>
  <div class="setting-callendar-th">
    <div class="box-setting-header ">
      <div class="title ml-4 mt-2">Calendrier TH</div>
      <div>
        <b-button
          v-if="checkPermission('CTTHAA')"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addCalenderModal
          ><font-awesome-icon icon="plus" /> Ajouter une année</b-button
        >
        <b-modal
          ref="addCalenderModal"
          id="addCalenderModal"
          title="Ajouter une année"
          :hide-footer="true"
          :hide-header="true"
          @hidden="resetModal()"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter une année</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addCalenderModal')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitCalender"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label=""
                  label-for="year"
                  class=" input-modal-champ mt-4"
                >
                  <b-form-select
                    id="year"
                    v-model="calenderAdd"
                    :options="ListYear"
                    required
                    class="b-form-select-raduis "
                  ></b-form-select>
                </b-form-group>
                <div class="actionModel">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style mt-3"
                    :disabled="loading"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="box-all-filter">
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Année</span>
        </div>
        <b-form-select
          class="b-form-select-new-style"
          v-model="filteryear"
          :options="ListYear"
          @change="handleChangeYear"
        ></b-form-select>
      </div>
      <div v-if="getLoadingCalenderTh" class="loading">
        <div class="spinner-border loadingFetch" role="status"></div>
      </div>
      <!-- <div
        v-if="getLoadingCalenderTh"
        class=" chargement-message-calender ml-2"
      >
        Chargement en cours ...
      </div> -->
      <div class="error-message">
        <div v-if="getErrorCalenderTh" class="error">
          <ul v-if="Array.isArray(getErrorCalenderTh)">
            <li v-for="(e, index) in getErrorCalenderTh" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ getErrorCalenderTh }}</div>
        </div>
      </div>
    </div>
    <div class="calender-body">
      <div class="calender-card">
        <div
          v-for="(item, indexMonth) in getCalendersTh"
          :key="'mounths' + item.id"
          class="card-mounts"
        >
          <div>
            <div class="card-mounths-header ">
              {{ item.month_name | FormateMonthName }}
            </div>
            <div class="card-mountes-body">
              <div
                class="card-mounths-item"
                v-for="(week, index) in item.weeks"
                :key="index + week"
              >
                <div v-if="checkPermission('CTTHDLS')">
                  <font-awesome-icon
                    v-if="index == 0 && item.month != 1"
                    icon="angle-left"
                    class="icon-flesh"
                    title="Déplacer la semaine"
                    @click="
                      handleDragWeek({
                        type: 'backward',
                        week: week,
                        month: item,
                        month_to: getCalendersTh[indexMonth - 1].id,
                        year: getCalendersTh.year
                      })
                    "
                  />
                </div>

                <div>{{ week.num | FormatWeek }}</div>
                <div class="conteur-depot-in-week">
                  {{ week.depots.length }}
                </div>
                <div class="box-rigth-details">
                  <div v-if="checkPermission('CTTHV')">
                    <font-awesome-icon
                      icon="eye"
                      class="icon-eye"
                      @click="handleShowSupportFiliale(item, week, index)"
                    />
                  </div>
                  <div
                    v-if="checkPermission('CTTHDLS')"
                    :class="{
                      'ml-2':
                        index == item.weeks.length - 1 && item.month != 12,
                      'ml-3': !(
                        index == item.weeks.length - 1 && item.month != 12
                      )
                    }"
                  >
                    <font-awesome-icon
                      v-if="index == item.weeks.length - 1 && item.month != 12"
                      icon="angle-right"
                      class="icon-flesh"
                      title="Déplacer la semaine"
                      @click="
                        handleDragWeek({
                          type: 'forward',
                          week: week,
                          month: item,
                          month_to: getCalendersTh[indexMonth + 1].id,
                          year: getCalendersTh.year
                        })
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- show depot week modal  -->
    <b-modal
      ref="addDepotWeekModal"
      id="addDepotWeekModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting"></div>
        <div class="iconClose" @click.prevent="hideModal('addDepotWeekModal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <form
        class="form-modal-custom-style"
        v-if="
          monthToUpdate &&
            monthToUpdate.weeks &&
            monthToUpdate.weeks[indexWeek] &&
            monthToUpdate.weeks[indexWeek].depots
        "
      >
        <div class="card-mounts">
          <div class="card-mounths-header ">
            {{ 'Semaine ' + monthToUpdate.weeks[indexWeek].num }}
          </div>

          <div
            class="box-depot-week-item"
            v-for="itemF in monthToUpdate.weeks[indexWeek].filiales"
            :key="itemF.id"
          >
            <div class="groupe-filiale-box">
              <div class="card-mounths-header text-left">
                {{ itemF.name }}
              </div>
              <div
                class="card-mounths-item-modal"
                v-for="itemD in itemF.depots"
                :key="itemD.id"
              >
                <div>
                  <b-form-select
                    class="b-form-select-new-style b-form-select-new-style-custom"
                    v-model="computedDepotName(itemD).filiale"
                    :options="getSettingFilialesTh"
                    text-field="name"
                    value-field="name"
                    @change="updateFilialeDepot($event, itemD)"
                  ></b-form-select>

                  - {{ computedDepotName(itemD).year }} -
                  <input
                    class="input-focus-depot"
                    placeholder="Nom de dépôt"
                    @change="updateNameDepot($event, itemD)"
                    title="Modifier le nom de dépôt"
                    :value="computedDepotName(itemD).name"
                  />
                </div>
                <div v-if="checkPermission('CTTHSLD')">
                  <font-awesome-icon
                    icon="times"
                    class="icon-flesh"
                    title="Supprimer la dépôt"
                    @click="DeleteDepotInMonth(itemD, itemF)"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            class="depot-card-modal card-mounths-item-modal mt-4"
            v-if="weekToUpdate != null"
          >
            <b-form-select
              id="filiale"
              v-model="filialeToAdd"
              :options="getSettingFilialesTh"
              required
              text-field="name"
              value-field="name"
              class="b-form-select-new-style b-form-select-new-style-custom"
            ></b-form-select>
            <div>- {{ filteryear }} -</div>
            <b-form-input
              class="input-focus-depot"
              autocomplete="off"
              id="depot-name"
              v-model="depotToAdd"
              required
            ></b-form-input>
          </div>

          <div class="button-add-depot" v-if="checkPermission('CTTHAND')">
            <b-button
              variant="success"
              class="depot-add-btn  ml-2"
              @click="
                weekToUpdate != null
                  ? handleSubmitDepot()
                  : handleShowAddDepotModal(weekDepotToUpdate)
              "
              ><font-awesome-icon icon="plus" />
              {{
                weekToUpdate == null ? 'Numéro de dépôt' : 'Ajouter la dépôt'
              }}</b-button
            >
          </div>
          <div
            v-if="getLoadingCalenderWeekTh"
            class=" chargement-message-calender text-aligne-center mt-2"
          >
            Chargement en cours ...
          </div>
        </div>

        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Calendrier-TH',
  data() {
    return {
      calenderAdd: null,
      ListYear: [],
      filteryear: null,
      error: null,
      loading: false,
      depotToAdd: null,
      filialeToAdd: null,
      weekToUpdate: null,
      weekDepotToUpdate: null,
      monthToUpdate: null,
      indexWeek: null
    };
  },
  methods: {
    ...mapActions([
      'AddCalendrierTh',
      'getCallenderTh',
      'addDepotToMonth',
      'deleteDepotInMonth',
      'updateNameDepotTh',
      'getSettingFilialeTh',
      'updateFilialeDepotTh',
      'DragWeekTh'
    ]),
    resetModal() {
      this.calenderAdd = null;
      this.error = null;
      this.loading = false;
      this.depotToAdd = null;
      this.filialeToAdd = null;
      this.weekToUpdate = null;
      this.weekDepotToUpdate = null;
      this.monthToUpdate = null;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleSubmitCalender() {
      this.loading = true;
      const response = await this.AddCalendrierTh(this.calenderAdd);
      if (response.success) {
        this.filteryear = this.calenderAdd;
        this.error = null;
        this.loading = false;
        this.hideModal('addCalenderModal');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    handleChangeYear() {
      this.getCallenderTh(this.filteryear);
    },
    handleShowAddDepotModal(item) {
      this.weekToUpdate = item;
    },
    async handleSubmitDepot() {
      this.error = null;
      const response = await this.addDepotToMonth({
        Week: this.weekToUpdate,
        depot:
          this.depotToAdd.length == 1 ? '0' + this.depotToAdd : this.depotToAdd,
        filiale: this.filialeToAdd,
        month: this.monthToUpdate
      });
      if (response.succes == true) {
        this.weekToUpdate = null;
        this.depotToAdd = null;
        this.filialeToAdd = null;
        this.error = null;
      } else {
        this.error = response.error;
      }
    },
    async DeleteDepotInMonth(itemD, itemF) {
      this.error = null;
      const response = await this.deleteDepotInMonth({
        month: this.monthToUpdate,
        week: this.weekDepotToUpdate,
        depot: itemD,
        filiale: itemF
      });
      if (response.succes == true) {
        this.error = null;
      } else {
        this.error = response.error;
      }
    },
    async updateNameDepot(e, depot) {
      this.error = null;
      const response = await this.updateNameDepotTh({
        name:
          e.target.value.length == 1 ? '0' + e.target.value : e.target.value,
        month: this.monthToUpdate,
        depot: depot,
        filiale: this.computedDepotName(depot).filiale
      });
      if (response.succes == true) {
        this.error = null;
      } else {
        this.error = response.error;
      }
    },
    async updateFilialeDepot(e, depot) {
      this.error = null;
      const response = await this.updateFilialeDepotTh({
        month: this.monthToUpdate,
        depot: depot,
        depot_name: this.computedDepotName(depot).name,
        filiale: e
      });
      if (response.succes == true) {
        this.error = null;
      } else {
        this.error = response.error;
      }
    },
    handleShowSupportFiliale(month, week, index) {
      this.monthToUpdate = month;
      this.weekDepotToUpdate = week;
      this.indexWeek = index;
      this.$refs['addDepotWeekModal'].show();
    },
    handleDragWeek(paayload) {
      this.DragWeekTh(paayload);
    }
  },
  computed: {
    ...mapGetters([
      'getLoadingCalenderTh',
      'getCalendersTh',
      'getErrorCalenderTh',
      'getSettingFilialesTh',
      'getLoadingCalenderWeekTh',
      'checkPermission'
    ]),
    computedDepotName() {
      return function(name) {
        return {
          filiale: name.filiale_name,
          year: name.year,
          name: name.depot_number
        };
      };
    }
  },
  components: {
    Card: () => import('../component/card.vue')
  },
  filters: {
    FormateMonthName: value => {
      return value[0].toUpperCase() + value.slice(1);
    },
    FormatWeek: value => {
      switch (value) {
        case value:
          return 'Semaine ' + value;
        default:
          return value;
      }
    }
  },
  mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.getCallenderTh(this.filteryear);
    this.getSettingFilialeTh();
  }
};
</script>

<style scoped lang="scss">
.setting-callendar-th {
  width: 100%;

  .calender-body {
    margin-top: 5px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 205px);
    .calender-card {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 7px;
      justify-content: center;
    }
  }
  .card-mounts {
    border: 1px solid rgba(108, 117, 125, 0.30196078431372547);
    margin-right: 8px;
    background-color: #332fc838;
    border-radius: 4px;
    display: table-cell;
    vertical-align: top;
    padding: 2px;
    margin: 5px;
    width: 181px;
    .card-mounths-header {
      position: relative;
      width: 100%;
      padding: 0px;
      font-weight: 600;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
    }
    .card-mountes-body {
      .card-mounths-item {
        position: relative;
        overflow: visible;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        border: 1px solid rgba(108, 117, 125, 0.3215686274509804);
        padding: 4px;
        background-color: rgb(245 244 250);
        width: 100%;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        .icon-flesh {
          cursor: pointer;
          font-size: 10px;
          margin-right: 2px;
          color: #6f6ecb;
          &:hover {
            font-size: 13px;
          }
        }
        .icon-eye {
          cursor: pointer;
          font-size: 10px;
          margin-right: 2px;
          color: #4d4bac;
          &:hover {
            font-size: 13px;
          }
        }
        .conteur-depot-in-week {
          background-color: rgb(54 52 170 / 46%);
          min-width: 17px;
          color: white;
          text-align: center;
          height: 17px;
          border-radius: 14px;
          text-align: center;
          font-size: 9px;
        }
        .input-focus-depot {
          width: 25%;
          background-color: transparent;
          border: none;
          color: #000;
          overflow: visible;
          border: 0;
          font-size: 13px;
          text-overflow: ellipsis;
          outline: unset;
          &:focus {
            background-color: #ededf4;
          }
        }
        .b-form-select-new-style {
          border-radius: 5px;
          width: 110px;
          background-color: #fcfcfc;
          padding-left: 2px;

          padding-top: 0px;

          padding-bottom: 0px;

          height: 20px;
        }
        .box-rigth-details {
          display: flex;
          justify-content: space-between;
        }
      }
      .button-add-depot {
        margin: auto;
        margin-top: 5px;
        text-align: center;
      }
    }
  }
}
</style>
<style lang="scss">
.depot-card-modal {
  background-color: #7a78dc2e !important;
  .input-focus-depot {
    height: 21px;
  }
}
.button-add-depot {
  margin: auto;
  margin-top: 5px;
  text-align: center;
}
.card-mounts {
  color: black;
  border: 1px solid rgba(108, 117, 125, 0.30196078431372547);
  margin-right: 8px;
  background-color: #332fc838;
  border-radius: 4px;
  vertical-align: top;
  padding: 2px;
  margin: 5px;
  .card-mounths-header {
    position: relative;
    width: 100%;
    padding: 0px;
    font-weight: 600;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
}
.card-mounths-item-modal {
  color: #000;
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686274509804);
  padding: 4px;
  background-color: #f5f4fa;
  width: 99%;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  .icon-flesh {
    cursor: pointer;
    font-size: 10px;
    margin-right: 2px;
    color: #6f6ecb;
    &:hover {
      font-size: 13px;
    }
  }
  .input-focus-depot {
    width: 40%;
    background-color: #fcfcfc;
    border: none;
    color: #000;
    overflow: visible;
    border: 0;
    border-radius: 5px;
    font-size: 13px;
    text-overflow: ellipsis;
    outline: unset;
    text-align: center;
    &:focus {
      background-color: #fcfcfc;
    }
  }
  .b-form-select-new-style {
    border-radius: 5px;
    width: 40%;
    background-color: #fcfcfc;
    padding-left: 2px;

    padding-top: 0px;

    padding-bottom: 0px;

    height: 20px;
  }
  .box-rigth-details {
    display: flex;
    justify-content: space-between;
  }
}
.groupe-filiale-box {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  background-color: #7a78dc70;
  border-radius: 5px;
}
.box-depot-week-item {
  max-height: 320px;
  overflow-y: auto;
}
.box-depot-week-item::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.box-depot-week-item::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.box-depot-week-item::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 7px;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
</style>
